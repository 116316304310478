@import url('https://fonts.googleapis.com/css?family=Montserrat');

/* Global Styles */
:root {
  --primary-color: #e3fcf7;
  --secondary-color: #07a384;
  --third-color:#014538;
  --dark-color: #333333;
  --darkish-color: #666;
  --light-color: #f6f6f6;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a,
.nav-link {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--darkish-color);
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Background */

.bg-primary-local {
  background: var(--primary-color);
}

.bg-light-local{
  background: var(--light-color);
}

.bg-white-local {
  background-color: #fff;
}

/* Navbar */
.active {
  font-weight: bold;
  color: var(--third-color) !important;
}

/* Text */

/* Text  Color*/
.primary-color-local {
  color: var(--primary-color);
}

.secondary-color-local {
  color: var(--secondary-color);
}

/* Text Sizing */
.brand-name {
  font-size: 1.37rem;
  font-weight: bold;
}

.small-subtitle-text {
  font-size: 0.75rem;
  font-weight: 100;
}

/* Calendar */
.calendar {
  width: 100%;
}

.scroll {
  max-height: 700px;
  overflow-y: auto;
}

.citaday-text {
  color: rgba(0, 0, 0, 0.5);
  border-bottom: rgba(0, 0, 0, 0.175) solid 0.7px; 
}

/* Buttons */

.btn-outline-local {
  border: var(--secondary-color) solid 1px;
  color: var(--secondary-color);
}

.btn-outline-local:hover,
.btn-outline-local.active {
  background-color: var(--secondary-color) !important;
  color: var(--light-color) !important;
}

/* CSS Loading */

@keyframes change-opacity {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
  
}

.animated-svg {
  animation: change-opacity 2s infinite;
  
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--secondary-color);
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

/* CLIENTES */

.cliente-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 30px;
}

@media screen and (max-width: 576px) {
  .cliente-list {
    display: block;
  }
}

@media screen and (min-width: 976px) {  
  .main-card {
    max-width: 60vw;
  }
}
